export const jobExperienceData = [
  {
    company: "Virtual Gaming Technology Limited",
    title: "Analyst Programmer (Frontend)",
    period: "05/2023 – 06/2023",
    learnt: [
      "As a Technical Lead and hands-on development of a web application using Vue and Node.js in AXA iShop",
      "Implemented REST API using Node.js to interact with Mulesoft and CosmosDB",
      "Deployed the web app to different environments using Red Hat OpenShift (Kubernetes)",
      "Transformed the business requirements to technical design, like redemption and referral scheme",
      "Updated the UI and frontend logics for BCT MPF webpage",
      "Experienced in using Adobe Experience Platform and Data collection to capture the user behaviors in a webpage built in React",
      "Performed extraction and load in MySQL, Azure and Salesforce",
      "Built automatic content authoring programs",
    ],
    logo: require("../../assets/images/companies/Virtual-Gaming-Technology-Limited-logo.png"),
  },
  {
    company: "Deloitte Digital",
    title: "Analyst II - Software Developer (Full-Stack)",
    period: "05/2022 – 04/2023",
    learnt: [
      "As a Technical Lead and hands-on development of a web application using Vue and Node.js in AXA iShop",
      "Implemented REST API using Node.js to interact with Mulesoft and CosmosDB",
      "Deployed the web app to different environments using Red Hat OpenShift (Kubernetes)",
      "Transformed the business requirements to technical design, like redemption and referral scheme",
      "Updated the UI and frontend logics for BCT MPF webpage",
      "Experienced in using Adobe Experience Platform and Data collection to capture the user behaviors in a webpage built in React",
      "Performed extraction and load in MySQL, Azure and Salesforce",
      "Built automatic content authoring programs",
    ],
    logo: require("../../assets/images/companies/Deloitte-Digital-logo.jpg"),
  },
  {
    company: "Sun Hung Kai Real Estate Agency Ltd",
    title: "Data Science Trainee",
    period: "10/2021 – 05/2022",
    learnt: [
      "Built OCR and NLP models on receipts for E-Commerce",
      "Collected and analyzed customer's activities in malls and shops",
      "Built a car plate detection and recognition model",
      "Analyzed the customer's behaviors from telecom data and CCTV videos using Pandas and Tableau",
      "Customer Segmentation (e.g. lifestyle, interests, behavior and demographics)",
      "Analyzed campaigns and derived target list for campaigns in AWS S3 bucket",
      "Constructed airflow tasks for automatic routine jobs",
    ],
    logo: require("../../assets/images/companies/SHK-logo.jpg"),
  },
  {
    company: "Datax Limited",
    title: "AI System Engineer",
    period: "07/2020 – 10/2021",
    learnt: [
      "Research and development in AI-related projects",
      "Built a model combined motion detection and object detection to figure out frequent haunts of rodents for FEHD",
      "Built a model combined skeletion detection and object detection to check the equipment wearing process of healthcare professional for HA",
      "Built a model to detect fall down, run, detour and backward looking in mall level",
      "Built a snooker detection model and combine it with projector to derive different responsive games",
      "Built redis server and Slack bot",
    ],
    logo: require("../../assets/images/companies/Datax-logo.jpg"),
  },
  {
    company: "Centre for Learning Sciences and Technologies, CUHK",
    title: "Android Developer (Part-Time)",
    period: "07/2020 – 10/2021",
    learnt: [
      "Developed an Android version of EduVentureX individually, a GPS-based education app used by over 300 schools and 20,000 students in Hong Kong, Taiwan and Mainland China",
      "https://play.google.com/store/apps/details?id=caite.cuhk.eduventurex&hl=zh_HK&gl=US",
    ],
    logo: require("../../assets/images/colleges/CUHK-logo.png"),
  },
  {
    company: "Hey5 Limited",
    title: "Software Engineer (Intern)",
    period: "06/2019 – 08/2019",
    learnt: [
      "Helped develop a mobile app using React Native and GraphQL",
      "Designed several functions to facilitate the users",
    ],
    logo: require("../../assets/images/companies/Hey5-logo.jpg"),
  },
  {
    company: "EONIQ (HK) Limited",
    title: "Software Engineer (Intern)",
    period: "06/2018 – 08/2018",
    learnt: [
      "Helped develop and maintained the company website using Redux and React",
      "Updated and optimized the website to fulfill customers' demands",
    ],
    logo: require("../../assets/images/companies/EONIQ-logo.png"),
  },
];
